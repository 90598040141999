import React, { ReactElement } from 'react'
import AboutUsLayout from '../../components/Layout/AboutUs'

import SEO from '../../components/SEO/SEO'

import WhoWeAre from '../../components/AboutUsPage/WhoWeAre'

const AboutUsPage = ({ pageContext: { links } }: any): ReactElement => {
  return (
    <AboutUsLayout>
      <SEO title="ABOUT US - ALISI | Ayala Land" />
      <WhoWeAre links={links} />
    </AboutUsLayout>
  )
}

export default AboutUsPage
