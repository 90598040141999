import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import ContentNav from '../../ContentNav'
import Content from '../../Content'

import img1 from '../../../assets/images/about-us/who1.png'
import img2 from '../../../assets/images/about-us/who2.png'
import img3 from '../../../assets/images/about-us/who3.png'
import img4 from '../../../assets/images/about-us/who4.png'

const WhoWeAre = ({ links }: any): JSX.Element => {
  const aboutUsData = useStaticQuery(graphql`
    query {
      aboutUs {
        id
        name
        slug
        containers {
          name
          field_collections {
            name
            fields {
              name
              value
            }
          }
        }
      }
    }
  `)

  const data = aboutUsData.aboutUs.containers[0].field_collections
  const title = data ? data[0].fields[0].value : ''
  const body = data && data[1] ? data[1].fields : []
  const sample = aboutUsData.aboutUs.aboutUsImages

  const images = data && data[2] ? data[2].fields : []

  const handleImageLoad = (e: any) => {
    const imageHeight = e.target.naturalWidth

    // For image.naturalWidth < 785, display original size
    // 785, from '.image-cSover' media query
    if (imageHeight < 785) {
      e.target.classList.replace('image-hide', 'image-cover-s')
    } else {
      e.target.classList.replace('image-hide', 'image-cover')
    }
  }

  return (
    <>
      <ContentNav links={links} active={0} />
      <Content>
        <h1>{title}</h1>
        {body &&
          body.map((res: any, index: any) => {
            return <p key={index}>{res.value}</p>
          })}
        {images &&
          images.map((res: any, index: any) => {
            if (res.value !== 'No Data') {
              return <img className="image-hide" src={res.value} onLoad={handleImageLoad} key={index} />
            }
          })}
      </Content>
    </>
  )
}

export default WhoWeAre
